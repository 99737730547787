import TheVPP from '../components/TheVPP'

const TheVPPScreen = () => {
  return (
    <div className="container p-4">
      <div className="row">
        <TheVPP />
      </div>
    </div>
  )
}

export default TheVPPScreen
