import React from 'react'
import { Tab, Tabs } from 'react-bootstrap'

const Portfolio = () => {
  return (
    <div id="portfolio">
      <div className="titlesection">
        <h1>Portafolio</h1>
      </div>
      <Tabs
        defaultActiveKey="profile"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="home" title="Home">
          <p>
            Nisi eiusmod non veniam et velit cupidatat consequat culpa qui
            officia proident. Nisi minim nisi tempor cillum. Incididunt aliquip
            laboris sit aliquip excepteur nostrud ad ea fugiat. Nisi ad et elit
            qui amet occaecat sit magna non cupidatat exercitation laboris.
            Incididunt fugiat elit proident qui exercitation fugiat qui nulla.
            Quis aute voluptate aliquip consequat enim officia pariatur do nisi
            id. Labore et qui tempor anim sint incididunt pariatur fugiat
            ullamco magna quis.
          </p>
        </Tab>
        <Tab eventKey="profile" title="Profile">
          <p>
            Cillum cillum esse duis duis laborum consequat nulla sit anim.
            Voluptate culpa nisi minim laboris. Cillum laborum nostrud excepteur
            reprehenderit velit officia proident proident ut. Cillum voluptate
            Lorem commodo excepteur irure est ea nisi exercitation exercitation
            magna amet. Exercitation ipsum labore nostrud non esse sint ea
            consectetur fugiat magna sint aliquip minim veniam.
          </p>
        </Tab>
        <Tab eventKey="contact" title="Contact" disabled>
          <p>
            Sunt ut tempor elit id anim eu irure eu voluptate et pariatur
            occaecat ut velit. Occaecat qui tempor mollit excepteur non pariatur
            velit anim ut sint anim aliquip. Veniam quis magna velit ipsum
            consequat ullamco mollit ex labore excepteur. Cillum ex eu minim
            pariatur duis eu sit deserunt. Sit nisi Lorem qui veniam labore nisi
            reprehenderit velit. Commodo est laboris voluptate ex pariatur amet
            nisi ullamco do. Quis ipsum sint amet aliqua.
          </p>
        </Tab>
      </Tabs>
    </div>
  )
}

export default Portfolio
