import React from 'react'
import { Navbar, Container, Image, Nav, Row, Col } from 'react-bootstrap'
import './Header.css'

const Header = () => {
  return (
    <header>
      <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark" fixed="top">
        <Container>
          <Navbar.Brand href="/" hidden={true}>
            <Image
              src="/logo_250.png"
              roundedCircle
              alt="Logo"
              width={30}
              height={30}
            ></Image>{' '}
            Jose Alonso
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-center"
          >
            <Nav>
              <Nav.Link href="/#experience">Experiencia</Nav.Link>
              <Nav.Link eventKey={2} href="/#education">
                Educación
              </Nav.Link>
              <Nav.Link eventKey={3} href="/#skills">
                Habilidades
              </Nav.Link>
              <Nav.Link eventKey={4} href="/#testimonials">
                Testimonios
              </Nav.Link>
              <Nav.Link eventKey={5} href="/#portfolio">
                Portafolio
              </Nav.Link>
              <Nav.Link eventKey={6} href="/#contact">
                Contacto
              </Nav.Link>
              <Nav.Link eventKey={7} href="/heimdal/#heimdal">
                Heimdal
              </Nav.Link>
              <Nav.Link eventKey={7} href="/thevprivacypolicy/">
                The V Privacy Policy
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid className="theLogo text-center">
        <Row>
          <Col>
            <Image
              src="/logo_250.png"
              roundedCircle
              alt="Logo"
              width={160}
              height={160}
            ></Image>
          </Col>
        </Row>
        <Row>
          <Col>
            <h1>Jose Alonso</h1>
            <h3>Arquitecto de Software</h3>
          </Col>
        </Row>
        <Row>
          <Col className="profile pt-3">
            <Container>
              <p>
                Con más de 15 años de experiencia y una gran habilidad para
                desarrollar algoritmos, que me permite proveer una solución en
                casí cualquier lenguaje de programación
              </p>
              <p>
                Actualmente trabajo como Arquitecto de Software, diseñando
                Software, asegurando las mejores prácticas del Desarrollo de
                Software
              </p>
            </Container>
          </Col>
        </Row>
      </Container>
    </header>
  )
}

export default Header
