import React, { useEffect, useState } from 'react'
import HeimdalForm from './HeimdalForm'
import FormContainer from './FormContainer'
import { toast } from 'react-toastify'
import axios from 'axios'
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import methodsFile from '../data/Methods.js'

const Heimdal = () => {
  const [methods, setMethods] = useState([])
  const [password, setPassword] = useState('')
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const getMethods = () => {
    setMethods(methodsFile)
  }

  const generatePassword = async (method, length) => {
    if (method !== '') {
      let config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      let token

      await axios
        .post(
          'https://us-central1-heimdal-3415d.cloudfunctions.net/api/api/users/login/actions/automatic',
          config
        )
        .then((response) => {
          const resp = response.data
          if (resp.success === false) {
            toast('Error generating the strong password', {
              type: 'error',
            })
          } else {
            token = resp.data.token
          }
        })
        .catch((error) => {
          toast('Error generating the strong password', { type: 'error' })
        })

      if (token && token !== '') {
        // lets go fot the methods
        config.headers.authorization = `Bearer ${token}`

        await axios
          .post(
            'https://us-central1-heimdal-3415d.cloudfunctions.net/api/api/passwords',
            { methodCode: method, length: length },
            config
          )
          .then((response) => {
            const resp = response.data
            if (resp.success === false) {
              toast('Error generating the strong password', {
                type: 'error',
              })
            } else {
              setPassword(resp.data)
              toast('Password generated', { type: 'success' })
            }
          })
          .catch((error) => {
            toast('Error generating the strong password', {
              type: 'error',
            })
          })
      }
    } else {
      toast('Select a method', { type: 'error' })
    }
    setButtonDisabled(false)
  }

  const handleInputChange = (e) => {}

  const handleCopyPassword = (e) => {
    navigator.clipboard.writeText(password)
    toast('Password copied to clipboard', { type: 'info' })
  }

  // On the load
  useEffect(() => {
    getMethods()
  }, [])

  return (
    <div className="" id="heimdal">
      <div className="titlesection">
        <h1>Strong Passwords</h1>
      </div>
      <HeimdalForm {...{ generatePassword, buttonDisabled, methods }} />
      <FormContainer>
        <div className="mt-2">
          <div className="mb-3">
            <div className="input-group">
              <div className="input-group-text bg-light">
                <button
                  id="btn-copy-password"
                  className="btn btn-sm"
                  onClick={handleCopyPassword}
                >
                  <i className="material-icons">copy_all</i>
                </button>
              </div>
              <textarea
                className="form-control password"
                name="length"
                disabled
                placeholder="Strong password generated"
                value={password}
                onChange={handleInputChange}
                rows={4}
              ></textarea>
              <ReactTooltip
                anchorId="btn-copy-password"
                place="bottom"
                variant="info"
                content="Copy"
              />
            </div>
          </div>
        </div>
      </FormContainer>
    </div>
  )
}

export default Heimdal
