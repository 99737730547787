import React from 'react'
import { Button, Card } from 'react-bootstrap'

const Experience = () => {
  return (
    <div id="experience">
      <div className="titlesection">
        <h1>Experiencia</h1>
      </div>
      <Card>
        <Card.Body>
          <Card.Title>Edenred</Card.Title>
          <Card.Text className="xsmall">2016-Presente</Card.Text>
          <Card.Text>Comenzando como desarrollador senior, trabaje</Card.Text>
          <Button variant="primary">Go somewhere</Button>
        </Card.Body>
      </Card>
    </div>
  )
}

export default Experience
