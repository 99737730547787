import Heimdal from '../components/Heimdal'

const HeimdalScreen = () => {
  return (
    <div className="container p-4">
      <div className="row">
        <Heimdal />
      </div>
    </div>
  )
}

export default HeimdalScreen
