import React from 'react'
import { Card } from 'react-bootstrap'

const Contact = () => {
  return (
    <div id="contact">
      <div className="titlesection">
        <h1>Contacto</h1>
      </div>
      <Card body>This is some text within a card body.</Card>
    </div>
  )
}

export default Contact
