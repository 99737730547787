import { Container } from 'react-bootstrap'
import Header from './components/Header'
import Footer from './components/Footer'
import HomeScreen from './screens/HomeScreen'
import HeimdalScreen from './screens/HeimdalScreen'
import TheVPPScreen from './screens/TheVPPScreen'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const App = () => {
  return (
    <Router>
      <Header />
      <main className="py-3">
        <Container>
          <Routes>
            <Route path="/" element={<HomeScreen />} exact />
            <Route path="/heimdal" element={<HeimdalScreen />} exact />
            <Route path="/thevprivacypolicy" element={<TheVPPScreen />} exact />
          </Routes>
        </Container>
      </main>
      <Footer />
      <ToastContainer
        position="top-center"
        autoClose={2000}
        theme="colored"
        pauseOnFocusLoss
      />
    </Router>
  )
}

export default App
