import React, { useState, useEffect } from 'react'
import FormContainer from './FormContainer'
import RangeSlider from 'react-range-slider-input'
import 'react-range-slider-input/dist/style.css'

const HeimdalForm = (props) => {
  const initialValues = {
    method: '',
    length: 0,
    min: 0,
    max: Int16Array.max,
    methods: [],
  }

  const [values, setValues] = useState(initialValues)
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    setButtonDisabled(true)
    props.generatePassword(values.method, values.length)
  }

  const populateSelectMethods = () => {
    var items = []

    items.push(
      <option key="" disabled value="">
        Choose one method
      </option>
    )
    values.methods.map((method) => {
      return items.push(
        <option key={method.code} value={method.code}>
          {method.name}
        </option>
      )
    })

    return items
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target

    let length = 0
    let min = 0
    let max = 0

    if (name === 'method') {
      const m = values.methods.find((m) => m.code === value)

      if (values.length > m.maximumLength || values.length < m.minimumLength) {
        length = m.minimumLength
      } else {
        length = values.length
      }
      min = m.minimumLength
      max = m.maximumLength
      setValues({
        ...values,
        [name]: value,
        length: length,
        min: min,
        max: max,
      })
    } else if (name === 'lengthRange') {
      setValues({ ...values, length: value })
    } else {
      setValues({ ...values, [name]: value })
    }
  }

  // On load
  useEffect(() => {
    const obj = {
      method: '',
      length: 0,
      min: 0,
      max: Int16Array.max,
      methods: props.methods,
    }

    if (values.methods.length === 0) {
      setValues(obj)
    }
    setButtonDisabled(props.buttonDisabled)
  }, [props])

  return (
    <FormContainer>
      <form action="" className="card card-body" onSubmit={handleSubmit}>
        <div className="mb-3 ">
          <label className="form-label">Password Method</label>
          <div className="input-group">
            <div className="input-group-text bg-light">
              <i className="material-icons">gpp_good</i>
            </div>
            <select
              className="form-select"
              name="method"
              value={values.method}
              onChange={handleInputChange}
            >
              {populateSelectMethods()}
            </select>
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label">Password Length</label>
          <div className="input-group">
            <div className="input-group-text bg-light">
              <i className="material-icons">edit</i>
            </div>
            <input
              type="number"
              className="form-control"
              name="length"
              placeholder="Strong password length"
              min={values.min}
              max={values.max}
              value={values.length}
              onChange={handleInputChange}
            ></input>
          </div>

          <input
            type="range"
            className="range-slider grad mt-3"
            name="lengthRange"
            min={0}
            max={values.max}
            value={values.length}
            onChange={handleInputChange}
          ></input>
        </div>

        <button className="btn btn-primary btn-block" disabled={buttonDisabled}>
          {buttonDisabled ? 'Generating' : 'Generate'}
        </button>
      </form>
    </FormContainer>
  )
}

export default HeimdalForm
