import React from 'react'
import { Card, CardGroup } from 'react-bootstrap'

const Skills = () => {
  return (
    <div id="skills">
      <div className="titlesection">
        <h1>Habilidades</h1>
      </div>
      <CardGroup>
        <Card>
          <Card.Img variant="top" src="https://fakeimg.pl/425x160" />
          <Card.Body>
            <Card.Title>Card title 1</Card.Title>
            <Card.Text>
              This is a wider card with supporting text below as a natural
              lead-in to additional content. This content is a little bit
              longer.
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">Last updated 3 mins ago</small>
          </Card.Footer>
        </Card>
        <Card>
          <Card.Img
            variant="top"
            src="https://fakeimg.pl/425x160/ffaacc,128/000,255"
          />
          <Card.Body>
            <Card.Title>Card title 2</Card.Title>
            <Card.Text>
              This card has supporting text below as a natural lead-in to
              additional content.{' '}
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">Last updated 3 mins ago</small>
          </Card.Footer>
        </Card>
        <Card>
          <Card.Img
            variant="top"
            src="https://fakeimg.pl/425x160/ff0000,128/000,255"
          />
          <Card.Body>
            <Card.Title>Card title 3</Card.Title>
            <Card.Text>
              This is a wider card with supporting text below as a natural
              lead-in to additional content. This card has even longer content
              than the first to show that equal height action.
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">Last updated 3 mins ago</small>
          </Card.Footer>
        </Card>
      </CardGroup>
    </div>
  )
}

export default Skills
