import React from 'react';
import { Container } from 'react-bootstrap';
import Experience from '../components/Experience';
import Education from '../components/Education';
import Skills from '../components/Skills';
import Testimonials from '../components/Testimonials';
import Portfolio from '../components/Portfolio';
import Contact from '../components/Contact';

const HomeScreen = () => {
  return (
    <>
      <Container>
        <Experience />
        <Education />
        <Skills />
        <Testimonials />
        <Portfolio />
        <Contact />
      </Container>
    </>
  );
};

export default HomeScreen;
